import React from "react";

function Icon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="63"
      height="63"
      fill="none"
      viewBox="0 0 63 63"
      className={className}
    >
      <path
        fill="#ADC6FF"
        fillRule="evenodd"
        d="M62.146 31.073c0 17.162-13.913 31.074-31.074 31.074V31.073h31.074z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#80A5FF"
        fillRule="evenodd"
        d="M0 31.073C0 13.913 13.912 0 31.073 0v31.073H0z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Icon;
