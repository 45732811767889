import React, { useState } from "react";
import classNames from "classnames";

import EmailForm from "./components/EmailForm";
import CallbackForm from "./components/CallbackForm";
import "./ContactForm.styles.scss";

const ContactForm = () => {
  const [activeOption, setActiveOption] = useState("email");
  return (
    <div className="contact-form">
      <div className="contact-form__options">
        <div
          className={classNames("contact-form__option", {
            "contact-form__option--active": activeOption === "email",
          })}
          onClick={() => {
            setActiveOption("email");
          }}
        >
          Email
        </div>
        <div
          className={classNames("contact-form__option", {
            "contact-form__option--active": activeOption !== "email",
          })}
          onClick={() => {
            setActiveOption("callback");
          }}
        >
          Request a callback
        </div>
      </div>
      <EmailForm isDisabled={activeOption !== "email"} />
      <CallbackForm isDisabled={activeOption !== "callback"} />
    </div>
  );
};

export default ContactForm;
