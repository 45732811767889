import React, { useState } from "react";
import classNames from "classnames";
import { useToasts } from "react-toast-notifications";

import Input from "@components/Input";
import Select from "@components/Select";
import Button from "@components/Button";

import "./CallbackForm.styles.scss";

function encode(data) {
  return Object.keys(data)
    .map(
      (key) =>
        encodeURIComponent(key) + "=" + encodeURIComponent(data[key]),
    )
    .join("&");
}

const initialFields = {
  name: "",
  phone: "",
  hour: "",
  minutes: "",
  type: "",
};

const CallbackForm = ({ isDisabled }) => {
  const [fields, setFields] = useState([]);
  const [type, setType] = useState("AM");
  const { addToast } = useToasts();

  const handleChange = (e) => {
    const {
      target: { name, value },
    } = e;

    setFields({ ...fields, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    fetch("/", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: encode({
        "form-name": "callback",
        ...fields,
        type,
      }),
    })
      .then(() => {
        setFields(initialFields);
        addToast("Message sent successfully!", {
          appearance: "success",
          autoDismiss: true,
        });
      })
      .catch((error) => console.log(error));
  };

  return (
    <div
      className={classNames("email-form", {
        "email-form--disabled": isDisabled,
      })}
    >
      <form
        method="post"
        action="/"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={handleSubmit}
        className="callback-form__inner"
        name="callback"
      >
        <input type="hidden" name="type" value={type} />
        <div className="callback-form__inputs">
          <div className="contact-form__row">
            <Input
              name="name"
              placeholder="Name"
              onChange={handleChange}
              value={fields.name}
              required="true"
            />
          </div>
          <div className="contact-form__row">
            <Input
              name="phone"
              placeholder="Phone"
              onChange={handleChange}
              value={fields.phone}
              required="true"
            />
          </div>
          <div className="contact-form__row contact-form__row--time">
            <div className="contact-form__time__selects">
              <Select
                name="hour"
                placeholder="hour"
                options={new Array(13).fill(0).map((item, key) => {
                  return {
                    name: `${key < 10 ? "" : ""}${key}`,
                    value: `${key}`,
                  };
                })}
                onChange={handleChange}
                value={fields.hour}
              />
              <Select
                name="minutes"
                placeholder="minutes"
                options={[
                  {
                    name: `00`,
                    value: `00`,
                  },
                  {
                    name: `30`,
                    value: `30`,
                  },
                ]}
                onChange={handleChange}
                value={fields.minutes}
              />
            </div>
            <div className="contact-form__time__type">
              <div
                className={classNames("contact-form__time__button", {
                  "contact-form__time__button--active": type === "AM",
                })}
                onClick={() => {
                  setType("AM");
                }}
              >
                AM
              </div>
              <div
                className={classNames("contact-form__time__button", {
                  "contact-form__time__button--active": type === "PM",
                })}
                onClick={() => {
                  setType("PM");
                }}
              >
                PM
              </div>
            </div>
          </div>
        </div>

        <Button className="email-form__button" htmlType="submit">
          Let's connect
        </Button>
      </form>
    </div>
  );
};

export default CallbackForm;
