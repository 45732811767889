import React from "react";

import "./Select.styles.scss";

const SelectComp = ({
  id,
  name,
  placeholder,
  label,
  required,
  type,
  onChange,
  errors,
  value,
  options = [],
}) => {
  const errorObj =
    errors && errors.find((item) => item.name === name);
  return (
    <div className="input-wrapper">
      <label htmlFor={id}>
        {label} {required && <span className="required-pin">*</span>}
      </label>
      <select
        className="input"
        type={type}
        id={id}
        name={name}
        required={required}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
      >
        {options?.map((item,i) => {
          return <option key={i}value={item.value}>{item.name}</option>;
        })}
      </select>
      {<div className="error">{errorObj && errorObj.error}</div>}
    </div>
  );
};

export default SelectComp;
