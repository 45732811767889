import React from "react";
import classNames from "classnames";
import ReactMarkdown from "react-markdown";

import Category from "@components/Category";
import Button from "@components/Button";
import ButtonLink from "@components/ButtonLink";
import "./Info.styles.scss";

const Info = ({
  category,
  title,
  description,
  buttonLink,
  isWhite,
  children,
  isVertical,
  button,
  onButtonClick,
  href,
}) => {
  return (
    <div
      className={classNames("info", {
        "info--white": isWhite,
        "info--vertical": isVertical,
      })}
    >
      <div className="info__inner">
        <Category title={category} />
        <h2 className="info__title">
          <ReactMarkdown source={title} />
        </h2>
        {description && (
          <p className="info__description">
            <ReactMarkdown source={description} />
          </p>
        )}
        {href && <ButtonLink href={href} />}
        {button && (
          <div>
            <Button type="big" onClick={onButtonClick}>
              <a href={buttonLink}>{button}</a>
            </Button>
          </div>
        )}
        {children && <div className="info__children">{children}</div>}
      </div>
    </div>
  );
};

export default Info;
