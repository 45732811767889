import React from "react";
import ArrowIcon from "@components/svg/ArrowIcon";

import "./ButtonLink.styles.scss";

const ButtonLink = ({ href }) => {
  return (
    <a href={href} className="button-link">
      <span>Learn more</span> <ArrowIcon />
    </a>
  );
};

export default ButtonLink;
