import React from "react";
import Header from "@layouts/Header";
import DefaultSection from "@sections/DefaultSection";
import ContactDetails from "./components/ContactDetails";
import ContactForm from "@sections/Contact";

import "./Contact.styles.scss";

const ContactPageTemplate = ({ data }) => {
  const header = data?.header || {};
  const firstSection = data?.firstSection || {};
  const contact = data?.contact || {};

  return (
    <div>
      <Header {...header} />
      <DefaultSection
        info={{
          ...firstSection,
        }}
        rightSide={
          <ContactDetails
            address={firstSection?.address}
            email={firstSection?.email}
          />
        }
      />
      <ContactForm
        info={{
          ...contact,
          isWhite: true,
        }}
        hasImage
      />
    </div>
  );
};

export default ContactPageTemplate;
