import React from "react";
import Layout from "@layouts/Layout";

import ContactTemplate from "./Contact";

const ContactPage = ({ data: initialData }) => {
  const data = initialData?.markdownRemark?.frontmatter;

  const seo = {
    ...(data?.seo || {}),
    image: data?.seo?.image?.childImageSharp?.fluid?.src,
  };

  return (
    <Layout seo={seo}>
      <ContactTemplate data={data} />
    </Layout>
  );
};

export default ContactPage;

export const contactPageQuery = graphql`
  query ContactPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "Contact" } }) {
      frontmatter {
        seo {
          title
          description
          tags
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        header {
          title
          subTitle
          description
          buttonText
          video {
            name
            publicURL
          }
        }
        firstSection {
          category
          title
          description
          address
          email
        }
        contact {
          category
          title
          description
          images {
            image {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
