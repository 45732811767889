import React from "react";

function Icon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="106"
      height="71"
      fill="none"
      viewBox="0 0 106 71"
      className={className}
    >
      <path
        fill="#F8B0C8"
        fillRule="evenodd"
        d="M68.147 0L35.553 36.588l37.335 33.258C90.888 49.64 88.766 18.368 68.146 0z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#F8B0C8"
        fillRule="evenodd"
        d="M36.21 70.486V35H0c0 19.598 16.212 35.486 36.21 35.486z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Icon;
