import React, { useState } from "react";
import classNames from "classnames";
import { useToasts } from "react-toast-notifications";
import Input from "@components/Input";
import Textarea from "@components/Textarea";
import Button from "@components/Button";

import "./EmailForm.styles.scss";

function encode(data) {
  return Object.keys(data)
    .map(
      (key) =>
        encodeURIComponent(key) + "=" + encodeURIComponent(data[key]),
    )
    .join("&");
}

const initialFields = {
  name: "",
  company: "",
  email: "",
  phone: "",
  message: "",
};

const EmailForm = ({ isDisabled }) => {
  const [fields, setFields] = useState([]);
  const { addToast } = useToasts();

  const handleChange = (e) => {
    const {
      target: { name, value },
    } = e;

    setFields({ ...fields, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    fetch("/", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: encode({
        "form-name": "contact",
        ...fields,
      }),
    })
      .then(() => {
        setFields(initialFields);
        addToast("Message sent successfully!", {
          appearance: "success",
          autoDismiss: true,
        });
      })
      .catch((error) => console.log(error));
  };

  return (
    <div
      className={classNames("email-form", {
        "email-form--disabled": isDisabled,
      })}
    >
      <form
        method="post"
        action="/"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={handleSubmit}
        className="email-form__inner"
        name="contact"
      >
        <div className="email-form__inputs">
          <div className="contact-form__row">
            <Input
              name="name"
              placeholder="Name"
              onChange={handleChange}
              value={fields.name}
              required="true"
            />
            <Input
              name="company"
              placeholder="Company"
              onChange={handleChange}
              value={fields.company}
              required="true"
            />
          </div>
          <div className="contact-form__row">
            <Input
              name="email"
              placeholder="Email"
              onChange={handleChange}
              value={fields.email}
              required="true"
            />
            <Input
              name="phone"
              placeholder="Phone"
              onChange={handleChange}
              value={fields.phone}
              required="true"
            />
          </div>
          <Textarea
            name="message"
            placeholder="Message"
            onChange={handleChange}
            value={fields.message}
            required="true"
          />
        </div>
        <Button className="email-form__button" htmlType="submit">
          Let's connect
        </Button>
      </form>
    </div>
  );
};

export default EmailForm;
