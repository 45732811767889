import React from "react";
import classNames from "classnames";
import Info from "@components/Info";
import ContactForm from "@containers/ContactForm";
import Image from "@components/Image";
import TestimonialsIcon from "@components/svg/TestimonialsIcon";
import ContactIcon from "@components/svg/ContactIcon";

import "./Contact.styles.scss";

const Contact = ({ info, images, hasImage }) => {
  return (
    <section
      className={classNames("contact", {
        "contact--has-image": hasImage,
      })}
    >
      <div className="contact__inner container">
        <ContactIcon className="contact__icon" />
        <div className="contact__info">
          <Info {...info}>
            <div className="contact__info__users">
              {info?.images?.map((item) => {
                return (
                  <div className="contact__info__user">
                    <Image data={item?.image} />
                    <div className="contact__info__user__pin">
                      <TestimonialsIcon />
                    </div>
                  </div>
                );
              })}
            </div>
          </Info>
        </div>
        <div className="contact__form">
          <ContactForm />
        </div>
      </div>
    </section>
  );
};

export default Contact;
