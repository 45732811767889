import React from "react";
import classNames from "classnames";

import RedIcon from "@components/svg/RedIcon";
import BlueIcon from "@components/svg/BlueIcon";

import Image from "@components/Image";
import Info from "@components/Info";

import "./DefaultSection.styles.scss";

const DefaultSection = ({
  className,
  info,
  image,
  isReverse,
  hasBackground,
  rightSide = "",
  isVertical = false,
  hasIcons = true,
  backgroundImage,
  Component,
  bgColor,
}) => {
  const sectionStyle = backgroundImage
    ? { backgroundImage: `url(${backgroundImage})` }
    : {};
  const backgroundColor = bgColor ? { backgroundColor: bgColor } : {};
  return (
    <section
      className={classNames(
        "default-section",
        {
          "default-section--reverse": isReverse,
          "default-section--has-background": hasBackground,
          "default-section--is-vertical": isVertical,
        },
        className,
      )}
      style={{ ...sectionStyle, ...backgroundColor }}
    >
      {hasBackground && hasIcons && (
        <>
          <RedIcon className="default-section__icon" />
          <BlueIcon className="default-section__icon default-section__icon--bottom" />
        </>
      )}
      {Component ? (
        <Component />
      ) : (
        <div className="default-section__container">
          <div className="default-section__inner container">
            <div className="default-section__info">
              <Info {...info} />
            </div>
            <div className="default-section__image-container">
              {image ? (
                <div className="default-section__image">
                  <Image data={image} />
                </div>
              ) : (
                rightSide
              )}
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default DefaultSection;
