import React from "react";

import "./Textarea.styles.scss";

const Textarea = ({
  id,
  name,
  placeholder,
  label,
  required,
  type,
  onChange,
  errors,
  value,
}) => {
  const errorObj =
    errors && errors.find((item) => item.name === name);
  return (
    <div className="input-wrapper">
      <label htmlFor={id}>
        {label}
      </label>
      <textarea
        className="input"
        type={type}
        id={id}
        name={name}
        required={required}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
      />
      {<div className="error">{errorObj && errorObj.error}</div>}
    </div>
  );
};

export default Textarea;
