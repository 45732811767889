import React from "react";

function Icon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="176"
      height="180"
      fill="none"
      viewBox="0 0 176 180"
      className={className}
    >
      <path
        fill="#EB175C"
        fillRule="evenodd"
        d="M176 0H0v180c97.202 0 176-80.59 176-180z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Icon;
