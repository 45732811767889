import React from "react";

function Icon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      className={className}
    >
      <path
        fill="#004CFF"
        d="M12.172 7H0v2h12.172l-5.364 5.364 1.414 1.414L16 8 8.222.222 6.808 1.636 12.172 7z"
      ></path>
    </svg>
  );
}

export default Icon;
