import React from "react";
import ReactMarkdown from "react-markdown";

import "./ContactDetails.styles.scss";

const ContactDetails = ({ address, email }) => {
  return (
    <div className="contact-details">
      <div className="containet-details__inner">
        <div className="contact-details__address">
          <ReactMarkdown source={address} />
        </div>
        <div className="contact-details__email">{email}</div>
      </div>
    </div>
  );
};

export default ContactDetails;
